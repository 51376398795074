// Exemplo de como adicionar o Chat Plugin em um componente React

import React, { useEffect, useRef } from 'react';

const ChatPlugin = () => {
    const MessengerRef = useRef();
    useEffect(() => {

        MessengerRef.current.setAttribute("page_id", "108688035869787");
        MessengerRef.current.setAttribute("attribution", "biz_inbox");
    
        window.fbAsyncInit = function () {
            window.FB.init({
                xfbml: true,
                version: 'v15.0'
            });
        };
    
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/pt_BR/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    
    }, [])

  return (
    <>
    <div id="fb-root" ></div>
        <div ref={MessengerRef} id="fb-customer-chat" className="fb-customerchat">
    </div>
    </>
  );
};

export default ChatPlugin;
