import React from 'react';
import './App.css';
import ChatPlugin from './ChatPlugin';

function App() {
  const numeroWhatsApp = '5531982805782';


  return (
    <div className="App">
      <header className="App-header">
        <img src={process.env.PUBLIC_URL + '/logo.png'} className="App-logo" alt="logo" />
        <p>Base natural de frutas para composição de sucos, sodas e drinks!</p>
        
        <p>
          Para fazer um pedido, entre em contato pelo chat ou por WhatsApp pelo número: 
          <a href={`https://api.whatsapp.com/send?phone=${numeroWhatsApp}`} target="_blank" rel="noopener noreferrer">
            <strong> (31) 98280-5782</strong>
          </a>
        </p>
        
        <img src={process.env.PUBLIC_URL + '/logo_fragus.png'} className="App-logo-fragus" alt="logo" />   
          <div className='Who'>
            <p>A Fragus Alimentos é uma micro indústria localizada na cidade de Belo Horizonte/MG que produz uma base <strong>100% natural</strong> no formato de sachê feita com frutas, sem álcool, pronta para você utilizar como base de <strong>sodas</strong> <strong>sucos</strong> ou <strong>drinks</strong>. Você escolhe!</p>
          </div>
      </header>
      <ChatPlugin />
    </div>
  );
}

export default App;
